<template>
    <div class="container_con">
        <el-card>
            <div class="con_title"><span></span> 新增菜单</div>
            <el-form ref="form" :rules="rules" :model="form" label-width="120px">
                <el-form-item label="标题：" prop="title">
                    <el-input v-model="form.title" maxlength="20" placeholder="请输入标题" style="width:300px" />
                </el-form-item>
                <el-form-item label="类型：" prop="type">
                    <el-select
                      v-model="form.type"
                      placeholder="请选择"
                      style="width:300px" filterable
                    >
                      <el-option :label="item.title" v-for="(item,index) in type_list" :key="index" :value="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="父级：" prop="pid">
                    <el-cascader style="width:300px" v-model="form.pid" :options="list"
                        :props="{ expandTrigger: 'hover',checkStrictly: true,emitPath:false,value:'id',label:'title',children:'_child'}"
                        clearable></el-cascader>
                </el-form-item>
                <el-form-item label="url：" prop="url">
                    <el-input v-model="form.url" maxlength="50" placeholder="请输入url" style="width:300px" />
                </el-form-item>
                <el-form-item label="图标:" prop="icon">
                  <SigleUpload :img="form.icon" accept="image/*" type="picture-card" formkey="icon" form="form" @PicturePreview="PicturePreview" @handleUploadSuccess="handleUploadSuccess" @handleUploadRemove="handleUploadRemove"></SigleUpload>
                </el-form-item>
                <el-form-item label="排序：">
                    <el-input-number v-model="form.sort" controls-position="right" :min="0" />
                </el-form-item>
                <el-form-item label="描述：">
                    <el-input type="textarea" v-model="form.remark" placeholder="请输入描述" maxlength="100"
                        style="width:450px" />
                </el-form-item>
            </el-form>
        </el-card>
    </div>
    <div class="bottom_btn">
        <el-button type="primary" @click="submitForm('form')" :loading="is_ajax">提交</el-button>
        <el-button @click="$router.go(-1)">取消</el-button>
    </div>
    
    <!-- 上传图片预览 -->
    <el-dialog :close-on-click-modal="false" title="图片预览" width="600px" v-model="uploadImgVisible">
      <img style="width: 100%;" :src="uploadImageUrl" alt="">
    </el-dialog>
    
</template>

<script>
    // 上传组件
    import SigleUpload from "@/components/sigleUpload";
    export default {
        name: "addDepartment",
        components: {SigleUpload},
        data() {
            return {
                id: '',
                is_ajax: false,
                form: {
                    title:'',
                    position:'1',
                    pid:0,
                    sort: 0
                },
                rules: {
                  title: [
                    { required: true, message: "标题不能为空",trigger: "blur"}
                  ],
                  type: [
                    { required: true, message: "类型不能为空",trigger: "blur"}
                  ],
                  url: [
                    { required: true, message: "url不能为空",trigger: "blur"}
                  ]
                },
                list: [],
                type_list:[
                    {title:'菜单',id:1},
                    {title:'导航',id:2},
                    {title:'按钮',id:3}
                ],
                // 上传图片
                uploadImageUrl: '',
                uploadImgVisible: false
            };
        },
        created() {
            this.id=this.$route.query.id || '';
            if(this.id){
                this.get_data()
            }else{
                this.getOptions()
            }
        },
        methods: {
            // 上传图片--s
            PicturePreview(file) {
              this.uploadImageUrl = file.url;
              this.uploadImgVisible = true;
            },
            handleUploadSuccess(res){
              this[res.form][res.key]=res.url
            },
            handleUploadRemove(res){
              this[res.form][res.key]=''
            },
            // 表单重置
            reset(pid) {
              this.form = {
                title:'',
                position:'1',
                pid:pid || 0,
                sort:0
              };
              if(this.$refs['form']){
                this.$refs['form'].clearValidate();
              }
            },
            /** 提交按钮 */
            submitForm: function(form) {
              this.$refs[form].validate((valid)  => {
                if (valid) {
                  this.is_ajax=true;
                  if(form=='form'){
                    if(this.form.id){
                      this.form.ids=this.form.id;
                      this.form.path=undefined;
                      this.form.level=undefined;
                      this.form.id=undefined;
                      this.$httpPost("/backend/Menu/add", this.form).then((res) => {
                          if (res.status == 200) {
                              this.$message.success('更新成功');
                              this.$router.go(-1)
                          } else {
                              this.$message.error(res.message);
                          }
                          this.is_ajax=false;
                      }).catch((err) => {
                          console.log(err);
                          this.is_ajax=false;
                      });
                    }else{
                        this.$httpPost("/backend/Menu/add", this.form).then((res) => {
                            if (res.status == 200) {
                                this.$message.success('新增成功');
                                // this.reset();
                                this.$router.go(-1)
                            } else {
                                this.$message.error(res.message);
                            }
                            this.is_ajax=false;
                        }).catch((err) => {
                            console.log(err);
                            this.is_ajax=false;
                        });
                    }
                  }
                }
              });
            },
            get_data(){
                this.$httpGet("/backend/Menu/operate/operate", {ids:this.id}).then((res) => {
                    if (res.status == 200) {
                        this.form=res.data.info;
                        this.list = res.data.menu_tree;
                    } else {
                        this.$message.error(res.message);
                    }
                }).catch((err) => {
                    console.log(err);
                });
            },
            getOptions() {
                this.loading = true;
                this.$httpGet("/backend/Menu/index", {}).then((res) => {
                    if (res.status == 200) {
                        this.list = res.data.menu_tree;
                    } else {
                        this.$message.error(res.message);
                    }
                }).catch((err) => {
                    console.log(err);
                });
            },
        }

    };
</script>

<style lang="scss" scoped>
    ::v-deep .el-upload-list--picture-card .el-upload-list__item {
      width: 80px;
      height: 80px;
    }
    ::v-deep .el-upload--picture-card {
      width: 80px;
      height: 80px;
      line-height: 80px;
    }
</style>
